import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { auth } from '../services/api';
import { useAuthStore } from '../store/authStore';
import { Box, Button, Container, TextField, Typography, CircularProgress } from '@mui/material';
import prasanviLogo from '../assets/images/prasanvi-logo-text.png';

const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('session_expiry');
  window.location.href = '/';
};

const scheduleLogout = (expiryTime) => {
  const expiryTimestamp = new Date(expiryTime).getTime();
  const currentTime = Date.now();
  const remainingTime = expiryTimestamp - currentTime;
  if (remainingTime > 0) {
      setTimeout(() => {
          alert('Session expired. Please log in again.');
          logout();
      }, remainingTime);
  } else {
      logout();
  }
};


function Login() {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const setUser = useAuthStore((state) => state.setUser);

  useEffect(() => {
    const validateToken = async () => {

      const token = localStorage.getItem('token');
      const expiryTime = localStorage.getItem('session_expiry');
      if (token && expiryTime) {
        const expiryTimestamp = new Date(expiryTime).getTime();
        try {
          const status = auth.validateToken(token);
          if (status) {
            const user = JSON.parse(localStorage.getItem('user'));
            setUser(user);
            if (Date.now() < expiryTimestamp) {
              scheduleLogout(expiryTime); // Re-schedule logout
              navigate('/dashboard');
              return; // Session is valid
            }
          } else {
            localStorage.removeItem('token');
          } 
        } catch (error) {
          console.error('Invalid token:', error);
          localStorage.removeItem('token');
        }
      }
    }
    validateToken();

    
  }, [navigate]);

  const handleSendOtp = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await auth.login(email);
      setIsOtpSent(true);
      toast.success('OTP sent to your email');
    } catch (error) {
      if (error.status === 501) {
        toast.error('Email not found, Please Register');
        navigate('/register');
      } else {
	      console.log(error,'error in login')
        toast.error(error.response.data.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { user, access_token, session_expiry } = await auth.verifyOTP(email, otp);
      localStorage.setItem('token', access_token);
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('session_expiry', session_expiry);
      navigate('/dashboard');
      toast.success('Login successful');
      scheduleLogout(session_expiry);
      setLoading(false);
    } catch (error) {
      console.log(error, 'error brooo')
      toast.error('Invalid OTP');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="xs">
      <Box textAlign="center" mt={8} mb={4}>
        <img src={prasanviLogo} alt="Prasanvi Logo" style={{ width: 250, marginLeft: -20 }} />
        <Typography variant="h6" component="h6" gutterBottom>
          Sign in to APK Scanner
        </Typography>
      </Box>

      <form onSubmit={isOtpSent ? handleVerifyOtp : handleSendOtp}>
        <Box mb={2}>
          <TextField
            id="email"
            label="Email Address"
            type="email"
            required
            fullWidth
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={isOtpSent}
          />
        </Box>
        
        {isOtpSent && (
          <Box mb={2}>
            <TextField
              id="otp"
              label="Enter OTP"
              type="text"
              required
              fullWidth
              variant="outlined"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
          </Box>
        )}

        <Box mt={3} mb={2}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} /> : null}
          >
            {loading ? (isOtpSent ? 'Verifying...' : 'Sending OTP...') : (isOtpSent ? 'Verify OTP' : 'Send OTP')}
          </Button>
        </Box>
      </form>

      <Box display="flex" justifyContent="center" mt={2}>
        <Link to="/register" style={{ textDecoration: 'none', color: '#3f51b5' }}>
          Register
        </Link>
        <Typography variant="body2" color="textSecondary" mx={1}>
          |
        </Typography>
        <Link to="#" style={{ textDecoration: 'none', color: '#3f51b5' }}>
          Contact Us
        </Link>
      </Box>
    </Container>
  );
}

export default Login;

