import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid2,
  Typography,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  TextField,
  CircularProgress,
} from '@mui/material';
import {
  TrendingUp as TrendingUpIcon,
  Warning as WarningIcon,
  CheckCircle as CheckCircleIcon,
  Schedule as ScheduleIcon,
  Delete as DeleteIcon,
  Refresh as RefreshIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';
import { analysis } from '../services/api';
import { toast } from 'react-hot-toast';

function Dashboard() {
  const [summaryData, setSummaryData] = useState({});
  const [scanData, setScanData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  // const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [loadingScanId, setLoadingScanId] = useState(null);

    const stats = [
    {
      title: 'Total Scans',
      value: summaryData?.total_scans ?? 0,
      icon: TrendingUpIcon,
      color: '#4f46e5',
    },
    {
      title: 'Critical Issues',
      value: summaryData?.critical_issues ?? 0,
      icon: WarningIcon,
      color: '#ef4444',
    },
    {
      title: 'Clean APKs',
      value: summaryData?.clean_apks ?? 0,
      icon: CheckCircleIcon,
      color: '#22c55e',
    },
    {
      title: 'Pending Analysis',
      value: summaryData?.pending_analysis ?? 0,
      icon: ScheduleIcon,
      color: '#f59e0b',
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await analysis.getDashData();
        setSummaryData(result.summary_data);
        setScanData(result.scan_data);
        setFilteredData(result.scan_data);
      } catch (error) {
        toast.error('Failed to fetch analysis results');
      }
    };
    fetchData();
  }, []);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);

    if (value === '') {
      setFilteredData(scanData);
    } else {
      const filtered = scanData.filter(
        (scan) =>
          scan.name.toLowerCase().includes(value) ||
          scan.app_name.toLowerCase().includes(value)
      );
      setFilteredData(filtered);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Completed':
        return 'primary.main';
      case 'Critical':
        return 'error.main';
      case 'Clean':
        return 'success.main';
      default:
        return 'warning.main';
    }
  };

  const handleDelete = async (id) => {
    console.log(`Delete action triggered for scan ID: ${id}`);
    try {
      const res = await analysis.delete(id);
      if (res.status === 'success') {
        toast.success('Scan deleted successfully');
        const scanToDelete = scanData.find((scan) => scan.id === id);
        if (scanToDelete) {
          if (scanToDelete.scan_status === 'Pending') {
            setSummaryData({
              ...summaryData,
              pending_analysis: summaryData.pending_analysis - 1,
            });
          } else if (scanToDelete.scan_status === 'Completed') {
            setSummaryData({
              ...summaryData,
              clean_apks: summaryData.clean_apks - 1,
            });
          }
        }
        setSummaryData({ ...summaryData, total_scans: summaryData.total_scans - 1 });
        const updatedData = scanData.filter((scan) => scan.id !== id);
        setScanData(updatedData);
        setFilteredData(updatedData);
      } else {
        toast.error('Failed to delete scan');
      }
    } catch (error) {
      console.log(error, 'error');
      toast.error('Failed to delete scan');
    }
  };
  const handleReRun = async (id) => {
    setLoadingScanId(id); // Set loading state
    try {
      const response = await analysis.rerun(id); // Example API call
      if (response.status === 'success') {
        toast.success('Re-run initiated successfully');
      } else {
        toast.error('Failed to initiate re-run');
      }
    } catch (error) {
      toast.error('An error occurred while initiating re-run');
    } finally {
      setLoadingScanId(null); // Reset loading state
    }
  };
  // const handleReRun = async (id) => {

  //   console.log(`Re-run action triggered for scan ID: ${id}`);
  // };

  const handleView = (id) => {
    console.log(`View action triggered for scan ID: ${id}`);
    window.location.href = `/scan/${id}`;
  };

  return (
    <>
    <Box>
      <Typography variant="h4" sx={{ mb: 4 }}>
        Dashboard
      </Typography>

      <Grid2 container spacing={3} sx={{ mb: 4 }}>
        {stats.map((stat) => (
          <Grid2 item xs={12} sm={6} md={3} key={stat.title}>
            <Card>
              <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  sx={{
                    mr: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 48,
                    height: 48,
                    borderRadius: 1,
                    bgcolor: `${stat.color}15`,
                  }}
                >
                  <stat.icon sx={{ color: stat.color }} />
                </Box>
                <Box>
                  <Typography color="text.secondary" variant="body2">
                    {stat.title}
                  </Typography>
                  <Typography variant="h5" sx={{ mt: 0.5 }}>
                    {stat.value}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid2>
        ))}
      </Grid2>

      <Card>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 3,
            }}
          >
            <Typography variant="h6">Recent Scans</Typography>
            <TextField
              size="small"
              variant="outlined"
              placeholder="Search Scans"
              value={searchTerm}
              onChange={handleSearch}
              sx={{ width: 300 }}
            />
          </Box>

          <TableContainer
            component={Paper}
            elevation={0}
            sx={{
              height: 300,
              maxHeight: 300,
              overflowY: 'auto',
            }}
          >
            {filteredData.length === 0 ? (
              <Box sx={{ textAlign: 'center', py: 10 }}>No scans performed</Box>
            ) : (
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Analysis Name</TableCell>
                    <TableCell>App Name</TableCell>
                    <TableCell>Timestamp</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Security Score</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((scan) => (
                    <TableRow key={scan.id}>
                      <TableCell>{scan.name}</TableCell>
                      <TableCell>{scan.app_name}</TableCell>
                      <TableCell>{scan.created_at}</TableCell>
                      <TableCell>
                        <Typography
                          component="span"
                          sx={{
                            color: getStatusColor(scan.scan_status),
                            bgcolor: `${getStatusColor(scan.scan_status)}15`,
                            px: 1,
                            py: 0.5,
                            borderRadius: 1,
                            fontSize: '0.875rem',
                          }}
                        >
                          {scan.scan_status}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {scan.security_score ? (
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <LinearProgress
                              variant="determinate"
                              value={scan.security_score}
                              sx={{ width: 100 }}
                            />
                            <Typography variant="body2">{scan.security_score}%</Typography>
                          </Box>
                        ) : (
                          '—'
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                          <Tooltip title="Delete">
                            <DeleteIcon
                              sx={{ cursor: 'pointer', color: 'error.main' }}
                              onClick={() => handleDelete(scan.id)}
                            />
                          </Tooltip>
                          <Tooltip title="Re-run">
                              <Box>
                                {loadingScanId === scan.id ? (
                                  <CircularProgress size={24} />
                                ) : (
                                  <RefreshIcon
                                    sx={{ cursor: 'pointer', color: 'info.main' }}
                                    onClick={() => handleReRun(scan.id)}
                                  />
                                )}
                              </Box>
                            </Tooltip>
                          <Tooltip
                            title={
                              scan.scan_status === 'Completed'
                                ? 'View'
                                : 'View disabled for incomplete scans'
                            }
                          >
                            <VisibilityIcon
                              sx={{
                                cursor: scan.scan_status === 'Completed' ? 'pointer' : 'not-allowed',
                                color: scan.scan_status === 'Completed'
                                  ? 'primary.main'
                                  : 'text.disabled',
                              }}
                              onClick={
                                scan.scan_status === 'Completed'
                                  ? () => handleView(scan.id)
                                  : undefined
                              }
                            />
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </CardContent>
      </Card>
    </Box>
    </>
  );
}

export default Dashboard;


// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   Card,
//   CardContent,
//   Grid2,
//   Typography,
//   LinearProgress,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Tooltip,
// } from '@mui/material';
// import {
//   TrendingUp as TrendingUpIcon,
//   Warning as WarningIcon,
//   CheckCircle as CheckCircleIcon,
//   Schedule as ScheduleIcon,
//   Delete as DeleteIcon,
//   Refresh as RefreshIcon,
//   Visibility as VisibilityIcon,
// } from '@mui/icons-material';
// import { analysis } from '../services/api';
// import { toast } from 'react-hot-toast';


// function Dashboard() {

//   const [summaryData, setSummaryData] = useState({});
//   const [scanData, setScanData] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const result = await analysis.getDashData();
//         setSummaryData(result.summary_data);
//         setScanData(result.scan_data);
//       } catch (error) {
//         toast.error('Failed to fetch analysis results');
//       }
//     };
//     fetchData();
//   },[]);

//   const stats = [
//     {
//       title: 'Total Scans',
//       value: summaryData?.total_scans ?? 0,
//       icon: TrendingUpIcon,
//       color: '#4f46e5',
//     },
//     {
//       title: 'Critical Issues',
//       value: summaryData?.critical_issues ?? 0,
//       icon: WarningIcon,
//       color: '#ef4444',
//     },
//     {
//       title: 'Clean APKs',
//       value: summaryData?.clean_apks ?? 0,
//       icon: CheckCircleIcon,
//       color: '#22c55e',
//     },
//     {
//       title: 'Pending Analysis',
//       value: summaryData?.pending_analysis ?? 0,
//       icon: ScheduleIcon,
//       color: '#f59e0b',
//     },
//   ];

 

//   const getStatusColor = (status) => {
//     switch (status) {
//       case 'Completed':
//         return 'primary.main';
//       case 'Critical':
//         return 'error.main';
//       case 'Clean':
//         return 'success.main';
//       default:
//         return 'warning.main';
//     }
//   };


//   const handleDelete = async(id) => {
//     console.log(`Delete action triggered for scan ID: ${id}`);
//     try{
//       const res = await analysis.delete(id);
//       if (res.status === 'success') {
//         toast.success('Scan deleted successfully');
//         const scanToDelete = scanData.find((scan) => scan.id === id);
//         if (scanToDelete) {
//           if (scanToDelete.scan_status === 'Pending') {
//             setSummaryData({ ...summaryData, pending_analysis: summaryData.pending_analysis - 1 });
//           } else if (scanToDelete.scan_status === 'Completed') {
//             setSummaryData({ ...summaryData, clean_apks: summaryData.clean_apks - 1 });
//           }
//         }
//         setSummaryData({ ...summaryData, total_scans: summaryData.total_scans - 1 });
//         setScanData(scanData.filter((scan) => scan.id !== id));
        
//       } else {
//         toast.error('Failed to delete scan');
//       }
//     } catch (error) {
//       console.log(error, 'error');
//       toast.error('Failed to delete scan');
//     }
//     // Add your delete API call or logic here
//   };
  
//   const handleReRun = async(id) => {
//     console.log(`Re-run action triggered for scan ID: ${id}`);
//   };
  
//   const handleView = (id) => {
//     console.log(`View action triggered for scan ID: ${id}`);
//     // Redirect to the detailed view page
//     window.location.href = `/scan/${id}`;
//   };

//   return (
//     <Box>
//       <Typography variant="h4" sx={{ mb: 4 }}>
//         Dashboard
//       </Typography>

//       <Grid2 container spacing={3} sx={{ mb: 4 }}>
//         {stats.map((stat) => (
//           <Grid2 item xs={12} sm={6} md={3} key={stat.title}>
//             <Card>
//               <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
//                 <Box
//                   sx={{
//                     mr: 2,
//                     display: 'flex',
//                     alignItems: 'center',
//                     justifyContent: 'center',
//                     width: 48,
//                     height: 48,
//                     borderRadius: 1,
//                     bgcolor: `${stat.color}15`,
//                   }}
//                 >
//                   <stat.icon sx={{ color: stat.color }} />
//                 </Box>
//                 <Box>
//                   <Typography color="text.secondary" variant="body2">
//                     {stat.title}
//                   </Typography>
//                   <Typography variant="h5" sx={{ mt: 0.5 }}>
//                     {stat.value}
//                   </Typography>
//                 </Box>
//               </CardContent>
//             </Card>
//           </Grid2>
//         ))}
//       </Grid2>

//       <Card>
//         <CardContent>
//           <Typography variant="h6" sx={{ mb: 3 }}>
//             Recent Scans
//           </Typography>
//           <TableContainer component={Paper} elevation={0}>
//             <Table>
//               <TableHead>
//                 <TableRow>
//                   <TableCell>Analysis Name</TableCell>
//                   <TableCell>App Name</TableCell>
//                   <TableCell>Timestamp</TableCell>
//                   <TableCell>Status</TableCell>
//                   <TableCell>Security Score</TableCell>
//                   <TableCell align="center">Actions</TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {scanData.map((scan) => (
//                   <TableRow key={scan.id}>
//                     <TableCell>{scan.name}</TableCell>
//                     <TableCell>{scan.app_name}</TableCell>
//                     <TableCell>{scan.created_at}</TableCell>
//                     <TableCell>
//                       <Typography
//                         component="span"
//                         sx={{
//                           color: getStatusColor(scan.scan_status),
//                           bgcolor: `${getStatusColor(scan.scan_status)}15`,
//                           px: 1,
//                           py: 0.5,
//                           borderRadius: 1,
//                           fontSize: '0.875rem',
//                         }}
//                       >
//                         {scan.scan_status}
//                       </Typography>
//                     </TableCell>
//                     <TableCell>
//                       {scan.security_score ? (
//                         <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
//                           <LinearProgress
//                             variant="determinate"
//                             value={scan.security_score}
//                             sx={{ width: 100 }}
//                           />
//                           <Typography variant="body2">{scan.security_score}%</Typography>
//                         </Box>
//                       ) : (
//                         '—'
//                       )}
//                     </TableCell>
//                     <TableCell align="center">
//                       {/* Actions */}
//                       <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
//                       <Tooltip title="Delete">
//                         <DeleteIcon
//                           sx={{ cursor: 'pointer', color: 'error.main' }}
//                           onClick={() => handleDelete(scan.id)}
//                         />
//                       </Tooltip>
//                       <Tooltip title="Re-run">
//                         <RefreshIcon
//                           sx={{ cursor: 'pointer', color: 'info.main' }}
//                           onClick={() => handleReRun(scan.id)}
//                         />
//                       </Tooltip>
//                       <Tooltip
//                     title={
//                       scan.scan_status === 'Completed'
//                         ? 'View'
//                         : 'View disabled for incomplete scans'
//                     }
//                   >
//                     <VisibilityIcon
//                       sx={{
//                         cursor: scan.scan_status === 'Completed' ? 'pointer' : 'not-allowed',
//                         color: scan.scan_status === 'Completed' ? 'primary.main' : 'text.disabled',
//                       }}
//                       onClick={
//                         scan.scan_status === 'Completed'
//                           ? () => handleView(scan.id)
//                           : undefined
//                       }
//                     />
//                   </Tooltip>
//                       </Box>
//                     </TableCell>
//                   </TableRow>
//                 ))}
//               </TableBody>
//             </Table>
//           </TableContainer>
//         </CardContent>
//       </Card>
//     </Box>
//   );
// }

// export default Dashboard;
