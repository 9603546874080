import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  Box, 
  Button, 
  TextField, 
  Typography, 
  Container, 
  Paper, 
  Alert 
} from '@mui/material';
import { Security as SecurityIcon } from '@mui/icons-material';
import toast from 'react-hot-toast';
import { auth } from '../services/api';
import prasanviLogo from "../assets/images/prasanvi-logo-text.png";

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await auth.forgotPassword(email);
      setIsEmailSent(true);
      toast.success('Reset instructions sent to your email');
    } catch (error) {
      toast.error('Failed to send reset instructions');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {/* <SecurityIcon color="primary" sx={{ fontSize: 40, mb: 2 }} /> */}
        <Box
          component="img"
          src={prasanviLogo}
          alt="Logo"
          sx={{ width: "150px", height: "auto", cursor: "pointer" }}
        />

        <Typography component="h1" variant="h5">
          Reset your password
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1, mb: 3, textAlign: 'center' }}>
          Enter your email address and we'll send you instructions to reset your password.
        </Typography>
        <Paper sx={{ p: 3, width: '100%' }}>
          {!isEmailSent ? (
            <Box component="form" onSubmit={handleSubmit}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={loading}
              >
                {loading ? 'Sending...' : 'Send reset instructions'}
              </Button>
            </Box>
          ) : (
            <Alert severity="success" sx={{ mb: 2 }}>
              We've sent password reset instructions to your email. Please check your inbox.
            </Alert>
          )}
          <Box sx={{ mt: 2, textAlign: 'center' }}>
            <Link to="/login" style={{ textDecoration: 'none' }}>
              <Typography color="primary">Back to sign in</Typography>
            </Link>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}

export default ForgotPassword
