import React, { useState } from "react";
import {
  Drawer,
  Toolbar,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
  Typography,
  Divider,
    Avatar,
    IconButton,
} from "@mui/material";
import {
  Info as InfoIcon,
  Security as SecurityIcon,
  List as ListIcon,
  Android as AndroidIcon,
  FileCopy as FileCopyIcon,
  Lock as LockIcon,
  ExpandLess,
  ExpandMore,
  BugReport as BugReportIcon,
  Assessment as AssessmentIcon,
  Language as LanguageIcon,
  Email as EmailIcon,
  Person as PersonIcon,
  Key as KeyIcon,
  FontDownload as FontIcon,
  LibraryBooks as LibraryBooksIcon,
  FilePresent as FilePresentIcon,
  Hearing as AssistiveListeningSystemsIcon,
  LibraryBooks as BufferIcon,
  Storage as StorageIcon,
  DeveloperBoard,
  Fingerprint as FingerprintIcon,
  FlagCircle,
  Search as SearchIcon,
  Code as CodeIcon,
  FingerprintRounded as Fingerprint,
  Logout as LogoutIcon,
} from "@mui/icons-material";
import prasanviLogo from "../assets/images/prasanvi-logo-text.png";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {

    const navigate = useNavigate();
  const [openSecurity, setOpenSecurity] = useState(false);
  const [openMalware, setOpenMalware] = useState(false);
  const [openReconnaissance, setOpenReconnaissance] = useState(false);
  const [openComponents, setOpenComponents] = useState(false);

  const menuIconSize = 18;
  const menuFontSize = "0.9rem";

  const user = JSON.parse(localStorage.getItem('user'));
  const handleScrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: 240,
        flexShrink: 0,
        "& .MuiDrawer-paper": { width: 240, boxSizing: "border-box" },
      }}
    >
      {/* <Toolbar /> */}
      {/* <Toolbar sx={{ justifyContent: 'left' }}>
        <Box
        component="img"
        src={prasanviLogo} // Replace with your logo URL
        alt="Logo"
        sx={{ width: '170px', height: 'auto', cursor: 'pointer' }}
        onClick={() => window.location.href = '/dashboard'} // Redirect to dashboard
        />
      </Toolbar> */}
      <Box sx={{ position: "fixed", top: 0, width: 240, zIndex: 1, background: "#fff" }}>
        <Toolbar sx={{ justifyContent: "left" }}>
          <Box
            component="img"
            src={prasanviLogo}
            alt="Logo"
            sx={{ width: "150px", height: "auto", cursor: "pointer" }}
            onClick={() => navigate("/dashboard")}
          />
        </Toolbar>
        <Divider />
        <ListItem>
          <ListItemText
            primary={
              <Typography variant="h6" sx={{ fontSize: "1rem" }}>
                Static Analyzer
              </Typography>
            }
          />
        </ListItem>
        <Divider />
      </Box>
      {/* <Box sx={{ overflow: "auto" }}> */}
      
            {/* <Divider />
          <ListItem>
          <ListItemText
          primary={
            <Typography variant="h6" sx={{ fontSize: "1rem" }}>
            Static Analyzer
            </Typography>
            }
            />
            </ListItem>
            <Divider /> */}
        <Box
        sx={{
          marginTop: "120px", // Adjust based on header height
        //   marginBottom: "80px", // Adjust based on footer height
          overflowY: "auto",
          height: "calc(100vh - 200px)", // Header + Footer height
        }}
      >
        <List>
          {/* Information */}
          <ListItem button="true" onClick={() => handleScrollTo("information")}>
            <ListItemIcon>
              <InfoIcon sx={{ fontSize: menuIconSize }} />
            </ListItemIcon>
            <ListItemText
              primary="Information"
              sx={{ "& span": { fontSize: menuFontSize } }}
            />
          </ListItem>

          {/* Signer Certificate */}
          <ListItem button="true" onClick={() => handleScrollTo("certificate")}>
            <ListItemIcon>
              <SecurityIcon sx={{ fontSize: menuIconSize }} />
            </ListItemIcon>
            <ListItemText
              primary="Signer Certificate"
              sx={{ "& span": { fontSize: menuFontSize } }}
            />
          </ListItem>

          {/* Permissions */}
          <ListItem button="true" onClick={() => handleScrollTo("permissions_analysis")}>
            <ListItemIcon>
              <ListIcon sx={{ fontSize: menuIconSize }} />
            </ListItemIcon>
            <ListItemText
              primary="Permissions"
              sx={{ "& span": { fontSize: menuFontSize } }}
            />
          </ListItem>

          {/* Android API */}
          <ListItem button="true" onClick={() => handleScrollTo("android_api")}>
            <ListItemIcon>
              <AndroidIcon sx={{ fontSize: menuIconSize }} />
            </ListItemIcon>
            <ListItemText
              primary="Android API"
              sx={{ "& span": { fontSize: menuFontSize } }}
            />
          </ListItem>

          {/* Browsable Activities */}
          <ListItem button="true" onClick={() => handleScrollTo("browsable")}>
            <ListItemIcon>
              <FileCopyIcon sx={{ fontSize: menuIconSize }} />
            </ListItemIcon>
            <ListItemText
              primary="Browsable Activities"
              sx={{ "& span": { fontSize: menuFontSize } }}
            />
          </ListItem>
          
          {/* Security Analysis */}
          <ListItem button="true" onClick={() => setOpenSecurity(!openSecurity)}>
            <ListItemIcon>
              <LockIcon sx={{ fontSize: menuIconSize }} />
            </ListItemIcon>
            <ListItemText
              primary="Security Analysis"
              sx={{ "& span": { fontSize: menuFontSize } }}
            />
            {openSecurity ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openSecurity} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button="true"
                sx={{ pl: 4 }}
                onClick={() => handleScrollTo("network_analysis")}
              >
                <ListItemIcon>
                  <LockIcon sx={{ fontSize: menuIconSize }} />
                </ListItemIcon>
                <ListItemText
                  primary="Network Security"
                  sx={{ "& span": { fontSize: menuFontSize } }}
                />
              </ListItem>
              <ListItem button="true" component="a" onClick={() => handleScrollTo('certificate_analysis')} sx={{ pl: 4 }}>
                <ListItemIcon>
                  <LanguageIcon sx={{ fontSize: menuIconSize }} />
                </ListItemIcon>
                <ListItemText primary="Certificate Analysis" sx={{ "& span": { fontSize: menuFontSize } }} />
              </ListItem>
              <ListItem button="true" component="a" onClick={() => handleScrollTo('manifest_analysis')} sx={{ pl: 4 }}>
                <ListItemIcon>
                  <SearchIcon sx={{ fontSize: menuIconSize }}  />
                </ListItemIcon>
                <ListItemText primary="Manifest Analysis"sx={{ "& span": { fontSize: menuFontSize } }} />
              </ListItem>
              <ListItem button="true" component="a" onClick={() => handleScrollTo('code_analysis')} sx={{ pl: 4 }}>
                <ListItemIcon>
                  <CodeIcon sx={{ fontSize: menuIconSize }}  />
                </ListItemIcon>
                <ListItemText primary="Code Analysis"sx={{ "& span": { fontSize: menuFontSize } }} />
              </ListItem>
              <ListItem button="true" component="a" onClick={() => handleScrollTo('binary_analysis')} sx={{ pl: 4 }}>
                <ListItemIcon>
                  <FlagCircle sx={{ fontSize: menuIconSize }} />
                </ListItemIcon>
                <ListItemText primary="Binary Analysis"sx={{ "& span": { fontSize: menuFontSize } }} />
              </ListItem>
              <ListItem button="true" component="a" onClick={() => handleScrollTo('niap_analysis')} sx={{ pl: 4 }}>
                <ListItemIcon>
                  <Fingerprint sx={{ fontSize: menuIconSize }} />
                </ListItemIcon>
                <ListItemText primary="NIAP Analysis" sx={{ "& span": { fontSize: menuFontSize } }} />
              </ListItem>
              <ListItem button="true" component="a" onClick={() => handleScrollTo('file_analysis')} sx={{ pl: 4 }}>
                <ListItemIcon>
                  <FileCopyIcon sx={{ fontSize: menuIconSize }} />
                </ListItemIcon>
                <ListItemText primary="File Analysis" sx={{ "& span": { fontSize: menuFontSize } }} />
              </ListItem>
              <ListItem button="true" component="a" onClick={() => handleScrollTo('firebase_analysis')} sx={{ pl: 4 }}>
                <ListItemIcon>
                  <FileCopyIcon sx={{ fontSize: menuIconSize }} />
                </ListItemIcon>
                <ListItemText primary="Firebase Analysis" sx={{ "& span": { fontSize: menuFontSize } }} />
              </ListItem>
            </List>
          </Collapse>

        {/* Malware Analysis */}
        <ListItem button="true" onClick={() => setOpenMalware(!openMalware)}>
            <ListItemIcon>
                <BugReportIcon />
            </ListItemIcon>
            <ListItemText primary="Malware Analysis" sx={{ "& span": { fontSize: menuFontSize } }} />
            {openMalware ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openMalware} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {/* <ListItem button component="a" onClick={() => document.getElementById('malware_analysis').scrollIntoView({ behavior: 'smooth' })} sx={{ pl: 4 }}>
                <ListItemIcon>
                  <FontDownloadIcon />
                </ListItemIcon>
                <ListItemText primary="Malware Permissions" />
              </ListItem> */}
                <ListItem button="true" component="a" onClick={() => handleScrollTo('apkid_analysis')} sx={{ pl: 4 }}>
                    <ListItemIcon>
                        <FingerprintIcon sx={{ "& span": { fontSize: menuFontSize } }} />
                    </ListItemIcon>
                    <ListItemText primary="APKiD Analysis" sx={{ "& span": { fontSize: menuFontSize } }} />
                </ListItem>
              {/* <ListItem button component="a" href="#behaviour">
                <ListItemIcon>
                  <SearchIcon />
                </ListItemIcon>
                <ListItemText primary="Behaviour Analysis" />
              </ListItem> */}
                <ListItem button="true" component="a" onClick={() => handleScrollTo('abused_perm')} sx={{ pl: 4 }}>
                    <ListItemIcon>
                        <DeveloperBoard sx={{ "& span": { fontSize: menuFontSize } }} />
                    </ListItemIcon>
                    <ListItemText primary="Abused Permissions" sx={{ "& span": { fontSize: menuFontSize } }} />
                </ListItem>
                
                <ListItem button="true" component="a" onClick={() => handleScrollTo('server_analysis')} sx={{ pl: 4 }}>
                    <ListItemIcon>
                        <LanguageIcon sx={{ "& span": { fontSize: menuFontSize } }} />
                    </ListItemIcon>
                    <ListItemText primary="Server Locations" sx={{ "& span": { fontSize: menuFontSize } }} />
                </ListItem>
          
                <ListItem button="true" component="a" onClick={() => handleScrollTo('domain_analysis')} sx={{ pl: 4 }}>
                    <ListItemIcon>
                        <SearchIcon sx={{ "& span": { fontSize: menuFontSize } }} />
                    </ListItemIcon>
                    <ListItemText primary="Domain Malware Check" sx={{ "& span": { fontSize: menuFontSize } }} />
                </ListItem>
            </List>
        </Collapse>

          {/* Reconnaissance */}
          <ListItem button="true" onClick={() => setOpenReconnaissance(!openReconnaissance)}>
            <ListItemIcon>
              <AssessmentIcon sx={{ fontSize: menuIconSize }} />
            </ListItemIcon>
            <ListItemText
              primary="Reconnaissance"
              sx={{ "& span": { fontSize: menuFontSize } }}
            />
            {openReconnaissance ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openReconnaissance} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button="true"
                sx={{ pl: 4 }}
                onClick={() => handleScrollTo("urls")}
              >
                <ListItemIcon>
                  <LanguageIcon sx={{ fontSize: menuIconSize }} />
                </ListItemIcon>
                <ListItemText
                  primary="URLs"
                  sx={{ "& span": { fontSize: menuFontSize } }}
                />
              </ListItem>
              <ListItem
                button="true"
                sx={{ pl: 4 }}
                onClick={() => handleScrollTo("emails")}
              >
                <ListItemIcon>
                  <EmailIcon sx={{ fontSize: menuIconSize }} />
                </ListItemIcon>
                <ListItemText
                  primary="Emails"
                  sx={{ "& span": { fontSize: menuFontSize } }}
                />
              </ListItem>
              <ListItem
                button="true"
                sx={{ pl: 4 }}
                onClick={() => handleScrollTo("trackers")}
              >
                <ListItemIcon>
                  <EmailIcon sx={{ fontSize: menuIconSize }} />
                </ListItemIcon>
                <ListItemText
                  primary="Trackers"
                  sx={{ "& span": { fontSize: menuFontSize } }}
                />
              </ListItem>
              <ListItem
                button="true"
                sx={{ pl: 4 }}
                onClick={() => handleScrollTo("secrets")}
              >
                <ListItemIcon>
                  <EmailIcon sx={{ fontSize: menuIconSize }} />
                </ListItemIcon>
                <ListItemText
                  primary="Hardcoded Secrets"
                  sx={{ "& span": { fontSize: menuFontSize } }}
                />
              </ListItem>
              <ListItem
                button="true"
                sx={{ pl: 4 }}
                onClick={() => handleScrollTo("strings")}
              >
                <ListItemIcon>
                  <EmailIcon sx={{ fontSize: menuIconSize }} />
                </ListItemIcon>
                <ListItemText
                  primary="Strings"
                  sx={{ "& span": { fontSize: menuFontSize } }}
                />
              </ListItem>
            </List>
          </Collapse>

          {/* Components */}
            <ListItem button="true" onClick={() => setOpenComponents(!openComponents)}>
                <ListItemIcon>
                <LibraryBooksIcon sx={{ fontSize: menuIconSize }} />
                </ListItemIcon>
                <ListItemText
                primary="Components"
                sx={{ "& span": { fontSize: menuFontSize } }}
                />
                {openComponents ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={openComponents} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem
                    button="true"
                    sx={{ pl: 4 }}
                    onClick={() => handleScrollTo("activities")}
                    >
                    <ListItemIcon>
                        <AssistiveListeningSystemsIcon sx={{ fontSize: menuIconSize }} />
                    </ListItemIcon>
                    <ListItemText
                        primary="Activities"
                        sx={{ "& span": { fontSize: menuFontSize } }}
                    />
                    </ListItem>
                    <ListItem
                    button="true"
                    sx={{ pl: 4 }}
                    onClick={() => handleScrollTo("services")}
                    >
                    <ListItemIcon>
                        <BufferIcon sx={{ fontSize: menuIconSize }} />
                    </ListItemIcon>
                    <ListItemText
                        primary="Services"
                        sx={{ "& span": { fontSize: menuFontSize } }}
                    />
                    </ListItem>
                    <ListItem
                    button="true"
                    sx={{ pl: 4 }}
                    onClick={() => handleScrollTo("receivers")}
                    >
                    <ListItemIcon>
                        <StorageIcon sx={{ fontSize: menuIconSize }} />
                    </ListItemIcon>
                    <ListItemText
                        primary="Receivers"
                        sx={{ "& span": { fontSize: menuFontSize } }}
                    />
                    </ListItem>
                    <ListItem
                    button="true"
                    sx={{ pl: 4 }}
                    onClick={() => handleScrollTo("providers")}
                    >
                    <ListItemIcon>
                        <DeveloperBoard sx={{ fontSize: menuIconSize }} />
                    </ListItemIcon>
                    <ListItemText
                        primary="Providers"
                        sx={{ "& span": { fontSize: menuFontSize } }}
                    />
                    </ListItem>
                    <ListItem
                    button="true"
                    sx={{ pl: 4 }}
                    onClick={() => handleScrollTo("libraries")}
                    >
                        <ListItemIcon>
                            <LanguageIcon sx={{ fontSize: menuIconSize }} />
                        </ListItemIcon>
                    <ListItemText
                        primary="Libraries"
                        sx={{ "& span": { fontSize: menuFontSize } }}
                    />
                    </ListItem>

                    <ListItem
                    button="true"
                    sx={{ pl: 4 }}
                    onClick={() => handleScrollTo("files")}
                    >
                        <ListItemIcon>
                            <FilePresentIcon sx={{ fontSize: menuIconSize }} />
                        </ListItemIcon>
                    <ListItemText
                        primary="Files"
                        sx={{ "& span": { fontSize: menuFontSize } }}
                    />
                    </ListItem>

                </List>
            </Collapse>
        </List>
      </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Divider />
        <Box sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Avatar src={user?.avatar || `https://ui-avatars.com/api/?name=${user?.name}`} />
                <Box sx={{ flex: 1, minWidth: 0 }}>
                    <Typography variant="subtitle2" noWrap>{user?.name}</Typography>
                </Box>
                <IconButton onClick={handleLogout} size="small">
                    <LogoutIcon />
                </IconButton>
            </Box>
        </Box>
    </Drawer>
  );
};

export default Sidebar;
