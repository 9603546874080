import React, { useLayoutEffect, useRef } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow';
import am4geodata_worldIndiaLow from '@amcharts/amcharts4-geodata/worldIndiaLow'
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

// Apply amCharts theme
am4core.useTheme(am4themes_animated);

const MapWithLocations = ({ serversInfo }) => {
  const chartRef = useRef(null);

 

  const locations = Object.keys(serversInfo).map((key) => ({
    name: key,
    latitude: parseFloat(serversInfo[key].geolocation.latitude),
    longitude: parseFloat(serversInfo[key].geolocation.longitude),
    city: serversInfo[key].geolocation.city,
    country: serversInfo[key].geolocation.country_long,
  }));

  useLayoutEffect(() => {
    // Create map chart
    const chart = am4core.create('chartdiv', am4maps.MapChart);

    // Set map definition
    chart.geodata = am4geodata_worldIndiaLow;

    // Set projection
    chart.projection = new am4maps.projections.Miller();

    // Add zoom control
    chart.zoomControl = new am4maps.ZoomControl();

    // Add polygon series (for map background)
    const polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
    polygonSeries.exclude = ["AQ"];
    polygonSeries.useGeodata = true;

    // Add image series for markers
    const imageSeries = chart.series.push(new am4maps.MapImageSeries());

    // Configure image template for markers
    const imageTemplate = imageSeries.mapImages.template;
    imageTemplate.propertyFields.latitude = 'latitude';
    imageTemplate.propertyFields.longitude = 'longitude';
    imageTemplate.tooltipText = '{name}\nCity: {city}\nCountry: {country}';

    // Add a circle to represent each marker
    const circle = imageTemplate.createChild(am4core.Circle);
    circle.radius = 6;
    circle.fill = am4core.color('#ff5733'); // Marker color
    circle.stroke = am4core.color('#FFFFFF'); // Marker border color
    circle.strokeWidth = 2;

    // Add location data
    imageSeries.data = locations;

    chartRef.current = chart;

    return () => {
      // Dispose chart
      chart.dispose();
    };
  }, []);

  return (
    <div
      id="chartdiv"
      style={{ width: '100%', height: '500px' }}
    ></div>
  );
};

export default MapWithLocations;
