import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  TextField,
  CircularProgress,
} from '@mui/material';
import {
  Upload as UploadIcon,
  Security as SecurityIcon,
} from '@mui/icons-material';
import toast from 'react-hot-toast';
import { analysis } from '../services/api';
import { useNavigate } from 'react-router-dom';

function Analysis() {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [analysisName, setAnalysisName] = useState('');
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(null);
  const [nameError, setNameError] = useState(false);
  const [fileError, setFileError] = useState(false);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile?.type === 'application/vnd.android.package-archive' || selectedFile?.name.endsWith('.apk')) {
      setFile(selectedFile);
      setFileError(false);
    } else {
      setFile(null);
      setFileError(true);
      toast.error('Please select a valid APK file');
    }
  };

  const handleNameChange = (event) => {
    const name = event.target.value;
    setAnalysisName(name);
    setNameError(!name.trim());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file || !analysisName.trim()) {
      toast.error('Please provide both a valid APK file and an analysis name.');
      return;
    }

    setLoading(true);

    try{
      const res = await analysis.checkName(analysisName);
    } catch(error) {
      if (error.response.status === 409) {
        setNameError(true);
        toast.error('Analysis Name already exists. Please choose a different name.');
      } else {
        toast.error('Please Try Again Later')
      }
      setLoading(false)
      // toast.error('Please Try Again Later')
      return
    }
    try {
      const result = await analysis.submit({ file, name : analysisName });
      setResults(result);
      toast.success('Analysis Started. We will update once it is done.');
      navigate('/dashboard');
    } catch (error) {
      toast.error('Failed to analyze APK');
    } finally {
      setLoading(false);
    }
  };



  

  return (
    <Box>
      <Typography variant="h4" sx={{ mb: 4 }}>
        APK Analysis
      </Typography>

      {/* <Grid2 container spacing={3} sx={{margin: '0 auto'}}> */}
        {/* <Grid2 item xs={12} md={6}> */}
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <Card sx={{width: '60%'}}>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 3 }}>
                Upload APK
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                }}
              >
                {/* Analysis Name Input */}
                <TextField
                  label="Analysis Name"
                  variant="outlined"
                  fullWidth
                  value={analysisName}
                  onChange={handleNameChange}
                  error={nameError}
                  helperText={nameError ? "Analysis name is required" : ""}
                />

                {/* APK File Upload Box */}
                <Box
                  sx={{
                    border: '2px dashed',
                    borderColor: 'primary.main',
                    borderRadius: 1,
                    p: 3,
                    textAlign: 'center',
                    cursor: 'pointer',
                    '&:hover': {
                      bgcolor: 'action.hover',
                    },
                  }}
                  onClick={() => document.getElementById('apk-file').click()}
                >
                  <input
                    id="apk-file"
                    type="file"
                    accept=".apk"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                  <UploadIcon sx={{ fontSize: 48, color: 'primary.main', mb: 1 }} />
                  <Typography variant="subtitle1" sx={{ mb: 1 }}>
                    Drop your APK file here or click to browse
                  </Typography>
                  {file && (
                    <Typography variant="body2" color="primary">
                      Selected: {file.name}
                    </Typography>
                  )}
                  {fileError && (
                    <Typography variant="body2" color="error">
                      Only APK files are allowed.
                    </Typography>
                  )}
                </Box>

                {/* Submit Button */}
                <Button
                  variant="contained"
                  type="submit"
                  disabled={!file || loading || nameError}
                  startIcon={loading ? <CircularProgress size={20} /> : <SecurityIcon />}
                >
                  {loading ? 'Analyzing...' : 'Start Analysis'}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Box>
    </Box>
  );
}

export default Analysis;

