import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { auth } from '../services/api';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';

function Register() {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    email: '',
    otp: '',
    name: '',
    mobile: '',
    companyName: '',
    userType: 'Individual', // "Individual" or "Company"
    address: {
      street: '',
      city: '',
      state: '',
      zip: '',
    },
  });
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const freeEmailDomains = ['gmail.com', 'yahoo.com', 'outlook.com', 'hotmail.com'];

  const isCorporateEmail = (email) => {
    const domain = email.split('@')[1];
    return !freeEmailDomains.includes(domain);
  };

  // Send OTP and check if user exists
  const handleSendOtp = async (e) => {
    e.preventDefault();

    // Additional email validation if the user type is "Company"
    if (formData.userType === 'Company' && !isCorporateEmail(formData.email)) {
      toast.error('Please provide a valid corporate email address.');
      return;
    }

    setLoading(true);

    try {
      const response = await auth.registerEmail({ email_id: formData.email });
      setIsOtpSent(true);
      toast.success('OTP sent to your email.');
      setStep(2);
    } catch (error) {
      if (error.response?.status === 501) {
        toast.error('User already exists. Please login.');
        navigate('/');
      } else {
        toast.error('Failed to send OTP');
      }
    } finally {
      setLoading(false);
    }
  };

  // Verify OTP
  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await auth.registerOTP({ email_id: formData.email, otp: formData.otp });
      toast.success('OTP verified. Continue with registration.');
      setStep(3);
    } catch (error) {
      toast.error('Invalid OTP');
    } finally {
      setLoading(false);
    }
  };

  // Final registration submission
  const handleFinalSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await auth.register(formData);
      toast.success('Registration successful! Please login.');
      navigate('/');
    } catch (error) {
      toast.error('Registration failed');
    } finally {
      setLoading(false);
    }
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith('address.')) {
      const field = name.split('.')[1];
      setFormData((prev) => ({
        ...prev,
        address: {
          ...prev.address,
          [field]: value,
        },
      }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  return (
    <Container maxWidth="xs">
      <Box textAlign="center" mt={8} mb={4}>
        <Typography variant="h4" component="h2" gutterBottom>
          {step === 1 && 'Enter your email'}
          {step === 2 && 'Verify OTP'}
          {step === 3 && 'Complete Registration'}
        </Typography>
      </Box>

      {step === 1 && (
        <form onSubmit={handleSendOtp}>
          <Box mb={2}>
            <ToggleButtonGroup
              value={formData.userType}
              exclusive
              onChange={(e, value) => setFormData({ ...formData, userType: value })}
              aria-label="User Type"
              fullWidth
            >
              <ToggleButton value="Individual">Individual</ToggleButton>
              <ToggleButton value="Company">Company</ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box mb={2}>
            <TextField
              id="email"
              label="Email Address"
              type="email"
              name="email"
              required
              fullWidth
              variant="outlined"
              value={formData.email}
              onChange={handleChange}
            />
          </Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
          >
            {loading ? 'Sending OTP...' : 'Send OTP'}
          </Button>
        </form>
      )}

      {step === 2 && (
        <form onSubmit={handleVerifyOtp}>
          <Box mb={2}>
            <TextField
              id="otp"
              label="Enter OTP"
              type="text"
              name="otp"
              required
              fullWidth
              variant="outlined"
              value={formData.otp}
              onChange={handleChange}
            />
          </Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
          >
            {loading ? 'Verifying...' : 'Verify OTP'}
          </Button>
        </form>
      )}

      {step === 3 && (
        <form onSubmit={handleFinalSubmit}>
          <Box mb={2}>
            <TextField
              id="name"
              name='name'
              label="Full Name"
              type="text"
              required
              fullWidth
              variant="outlined"
              value={formData.name}
              onChange={handleChange}
            />
          </Box>
          <Box mb={2}>
            <TextField
              id="mobile"
              name='mobile'
              label="Mobile Number"
              type="tel"
              required
              fullWidth
              variant="outlined"
              value={formData.mobile}
              onChange={handleChange}
            />
          </Box>
          {formData.userType === 'Company' && (
            <Box mb={2}>
              <TextField
                id="companyName"
                name='companyName'
                label="Company Name"
                type="text"
                required
                fullWidth
                variant="outlined"
                value={formData.companyName}
                onChange={handleChange}
              />
            </Box>
          )}
          <Box mb={2}>
            <TextField
              id="address.street"
              name='address.street'
              label="Street Address"
              type="text"
              fullWidth
              variant="outlined"
              value={formData.address.street}
              onChange={handleChange}
            />
          </Box>
          <Box mb={2}>
            <TextField
              id="address.city"
              name='address.city'
              label="City"
              type="text"
              fullWidth
              variant="outlined"
              value={formData.address.city}
              onChange={handleChange}
            />
          </Box>
          <Box mb={2}>
            <TextField
              id="address.state"
              name='address.state'
              label="State"
              type="text"
              fullWidth
              variant="outlined"
              value={formData.address.state}
              onChange={handleChange}
            />
          </Box>
          <Box mb={2}>
            <TextField
              id="address.zip"
              name='address.zip'
              label="ZIP Code"
              type="text"
              fullWidth
              variant="outlined"
              value={formData.address.zip}
              onChange={handleChange}
            />
          </Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
          >
            {loading ? 'Registering...' : 'Complete Registration'}
          </Button>
        </form>
      )}
    </Container>
  );
}

export default Register;
