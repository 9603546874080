import React, { useState } from 'react';

const ListComponent = ({ list, type, limit }) => {
  // State to manage the open/closed state of the details element
  // const [isOpen, setIsOpen] = useState(list.length < limit);
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  if (list.length === 0) return null;

  return (
    <details open={isOpen}>
      <summary onClick={handleToggle}>
        {list.length < limit ? (
          <>Showing <strong>{list.length}</strong> {type}</>
        ) : (
          <>Show all <strong>{list.length}</strong> {type}</>
        )}
      </summary>
      {list.map((val, index) => (
        <div key={index}>
          {val}
          <br />
        </div>
      ))}
    </details>
  );
};

export default ListComponent;
