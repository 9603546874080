import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Login from './pages/Login';
import Register from './pages/Register';
import ForgotPassword from './pages/ForgotPassword';
import Dashboard from './pages/Dashboard';
import Analysis from './pages/Analysis';
import Profile from './pages/Profile';
import Layout from './components/Layout';
import { useAuthStore } from './store/authStore';
// import StaticAnalysisReport from './pages/staticReport';
import StaticAnalysisDashboard from './pages/staticReport';
// import Notifications from './components/notifications';
import PrivateRoute from './components/privateRoute';


const theme = createTheme({
  palette: {
    primary: {
      main: '#4f46e5',
    },
    background: {
      default: '#f9fafb',
    },
  },
});


function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Toaster position="top-right" />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Layout />
              </PrivateRoute>
            }
          >
            <Route index element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route path="analysis" element={<PrivateRoute><Analysis /></PrivateRoute>} />
            <Route path="profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
          </Route>
          <Route path="/scan/:scanId" element={<PrivateRoute><StaticAnalysisDashboard /></PrivateRoute>} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;

