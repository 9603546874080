import { create } from 'zustand';

export const useAuthStore = create((set) => ({
  user: null,
  isAuthenticated: false,
  setUser: (user) => set({ user, isAuthenticated: !!user }),
  // logout: () => set({ user: null, isAuthenticated: false }),
  logout: () => {
    localStorage.removeItem('token'); // Clear token on logout
    set({ user: null, isAuthenticated: false });
  },
}));
