import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Avatar,
  Divider,
  Paper,
  MenuItem,
  Modal,
} from "@mui/material";
import toast from "react-hot-toast";
import { auth } from "../services/api";

function Profile() {
  const user = JSON.parse(localStorage.getItem("user"));
  const [editing, setEditing] = useState(false);
  const [formData, setFormData] = useState({
    email: user?.email_id || "",
    name: user?.name || "",
    type: user?.user_type || "Individual",
    mobile: user?.mobile_number || "",
    street: user?.address.street || "",
    city: user?.address.city || "",
    state: user?.address.state || "",
    companyName: user?.company_name || "",
    zip: user?.address.zip || "",
  });

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     setEditing(false);
  //     toast.success("Profile updated successfully");
  //   } catch (error) {
  //     toast.error("Failed to update profile");
  //   }
  // };


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Build the payload with changed values only
    const updatedFields = {};
    Object.keys(formData).forEach((key) => {
      if (formData[key] !== user[key]) {
        updatedFields[key] = formData[key];
      }
    });
  
    // If no changes were made, notify the user
    if (Object.keys(updatedFields).length === 0) {
      toast("No changes to save!");
      return;
    }
  
    try {
      // Replace this with your actual API call
      console.log("Sending updated fields to API:", updatedFields);
      // Example: await api.updateProfile(updatedFields);
      const res = await auth.updateProfile(updatedFields);
      setEditing(false); // Exit editing mode
      toast.success("Profile updated successfully");
    } catch (error) {
      console.log(error, 'error');
      toast.error("Failed to update profile");
    }
  };
  

  return (
    <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
      <Paper elevation={3} sx={{ p: 4, width: "60%", maxWidth: 800 }}>
        <Box sx={{ textAlign: "center", mb: 4 }}>
          <Avatar
            src={user?.avatar || `https://ui-avatars.com/api/?name=${user?.name}`}
            sx={{ width: 120, height: 120, mx: "auto", mb: 2 }}
          />
          <Typography variant="h5">{user?.name}</Typography>
          <Typography color="text.secondary">{user?.email}</Typography>
        </Box>

        <Divider sx={{ mb: 3 }} />

        <Typography variant="h6" sx={{ mb: 2 }}>
          Personal Information
        </Typography>

        <Box component="form" onSubmit={handleSubmit}>
          {[
            { label: "Email", value: "email", disabled: true },
            { label: "Full Name", value: "name", disabled: !editing },
            { label: "Type", value: "type", disabled: true, select: true },
            ...(formData.type === "Company"
              ? [{ label: "Company Name", value: "companyName", disabled: true }]
              : []),
            { label: "Mobile Number", value: "mobile", disabled: !editing },
            { label: "Street", value: "street", disabled: !editing },
            { label: "City", value: "city", disabled: !editing },
            { label: "State", value: "state", disabled: !editing },
            { label: "Zip Code", value: "zip", disabled: !editing },
          ].map((field, index) => (
            <Box key={index} sx={{ mb: 2 }}>
              <TextField
                fullWidth
                label={field.label}
                value={formData[field.value]}
                size="small"
                select={field.select || false}
                onChange={(e) =>
                  setFormData({ ...formData, [field.value]: e.target.value })
                }
                disabled={field.disabled}
              >
                {field.select &&
                  ["Individual", "Company"].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
              </TextField>
            </Box>
          ))}

          <Divider sx={{ my: 3 }} />

          <Typography variant="h6" sx={{ mb: 2 }}>
            Account Security
          </Typography>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              variant="outlined"
              color="error"
              onClick={() => toast("Account deletion feature coming soon!")}
            >
              Delete Account
            </Button>
          </Box>

          <Box sx={{ mt: 4, textAlign: "right" }}>
            {!editing ? (
              <Button variant="contained" onClick={() => setEditing(true)}>
                Edit Profile
              </Button>
            ) : (
              <>
                <Button
                  variant="outlined"
                  sx={{ mr: 2 }}
                  onClick={() => {
                    setEditing(false);
                    setFormData({ ...user }); // Reset form
                  }}
                >
                  Cancel
                </Button>
                <Button variant="contained" type="submit">
                  Save Changes
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}

export default Profile;



// import React, { useState } from 'react';
// import {
//   Box,
//   Button,
//   Card,
//   CardContent,
//   Grid2,
//   TextField,
//   Typography,
//   Avatar,
//   Divider,
// } from '@mui/material';
// import { useAuthStore } from '../store/authStore';
// import toast from 'react-hot-toast';

// function Profile() {
//   const { user, setUser } = useAuthStore();
//   const [editing, setEditing] = useState(false);
//   const [formData, setFormData] = useState({
//     name: user?.name || '',
//     email: user?.email || '',
//   });

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       // In a real app, you would call an API here
//       setUser({ ...user, ...formData });
//       setEditing(false);
//       toast.success('Profile updated successfully');
//     } catch (error) {
//       toast.error('Failed to update profile');
//     }
//   };

//   return (
//     <Box>
//       <Typography variant="h4" sx={{ mb: 4 }}>
//         Profile Settings
//       </Typography>

//       <Grid2 container spacing={3}>
//         <Grid2 item xs={12} md={4}>
//           <Card>
//             <CardContent sx={{ textAlign: 'center' }}>
//               <Avatar
//                 src={user?.avatar || `https://ui-avatars.com/api/?name=${user?.name}`}
//                 sx={{ width: 120, height: 120, mx: 'auto', mb: 2 }}
//               />
//               <Typography variant="h6">{user?.name}</Typography>
//               <Typography color="text.secondary" variant="body2">
//                 {user?.email}
//               </Typography>
//             </CardContent>
//           </Card>
//         </Grid2>

//         <Grid2 item xs={12} md={8}>
//           <Card>
//             <CardContent>
//               <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
//                 <Typography variant="h6">Personal Information</Typography>
//                 <Button
//                   variant={editing ? 'contained' : 'outlined'}
//                   onClick={() => !editing && setEditing(true)}
//                 >
//                   {editing ? 'Save Changes' : 'Edit Profile'}
//                 </Button>
//               </Box>

//               <Box component="form" onSubmit={handleSubmit}>
//                 <Grid2 container spacing={3}>
//                   <Grid2 item xs={12}>
//                     <TextField
//                       fullWidth
//                       label="Full Name"
//                       value={formData.name}
//                       onChange={(e) =>
//                         setFormData({ ...formData, name: e.target.value })
//                       }
//                       disabled={!editing}
//                     />
//                   </Grid2>
//                   <Grid2 item xs={12}>
//                     <TextField
//                       fullWidth
//                       label="Email Address"
//                       value={formData.email}
//                       disabled
//                       helperText="Email cannot be changed"
//                     />
//                   </Grid2>
//                 </Grid2>
//               </Box>

//               <Divider sx={{ my: 3 }} />

//               <Typography variant="h6" sx={{ mb: 2 }}>
//                 Account Security
//               </Typography>
//               <Button
//                 variant="outlined"
//                 color="error"
//                 sx={{ mr: 2 }}
//               >
//                 Change Password
//               </Button>
//               <Button variant="outlined" color="error">
//                 Delete Account
//               </Button>
//             </CardContent>
//           </Card>
//         </Grid2>
//       </Grid2>
//     </Box>
//   );
// }

// export default Profile
